@font-face {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: normal;
  src: local("Cabinet Grotesk Medium"),
    url("/fonts/CabinetGrotesk-Medium.woff") format("woff");
}

// @media (min-width: 768px) {
// }
// @media (min-width: 1280px) {
// }
// @media (min-width: 1536px) {
// }

.main-section {
  background-color: white;
  @media screen and (max-width: 767.5px) {
    h1 {
      text-align: center;
      margin-bottom: 2rem;
    }
  }
  .banner-container {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 955px;
    overflow-x: hidden;

    @media (min-width: 768px) {
      height: 1000px;
    }
    @media (min-width: 1280px) {
      height: 850px;
    }
    @media (min-width: 1536px) {
      height: 800px;
    }

    .pen-container {
      position: absolute;
      width: 100px;
      height: 170px;
      left: 30px;
      z-index: 10;
      bottom: 50px;
      display: none;
      @media (min-width: 1280px) {
        display: block;
      }
      @media (min-width: 1536px) {
        display: block;
      }
    }

    .image-container {
      position: relative;
      width: 100%;
      height: 85%;
    }
    .image-container2 {
      position: relative;
      width: 100%;
      height: 15%;
      box-shadow: 0px -7px 12px -5px rgba(0, 0, 0, 0.6);
    }
    .container-form {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0px;
      bottom: 0px;
      width: 100%;
      // margin-top: -55px;
      padding-top: 55px;

      // overflow: hidden;

      .button-side {
        position: absolute;
        left: 0;
        z-index: 20;
        top: -15%;
        bottom: 0;
        display: none;
        align-items: center;

        @media (min-width: 768px) {
          display: none;
        }
        @media (min-width: 1280px) {
          display: flex;
        }
        @media (min-width: 1536px) {
          display: flex;
        }

        .item-button-side {
          display: flex;
          transform: rotate(90deg);
          width: 55px;
          justify-content: center;

          @media (min-width: 768px) {
            width: 55px;
          }
          @media (min-width: 1280px) {
            width: 40px;
          }
          @media (min-width: 1536px) {
            width: 50px;
          }
        }
        a {
          background-color: #048ec3;
          font-size: 30px;
          padding: 6px 30px;
          border-radius: 20px 20px 0px 0px;
          color: white;
          text-decoration: none;
          filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
            drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));

          @media (min-width: 768px) {
            font-size: 30px;
            padding: 6px 30px;
          }
          @media (min-width: 1280px) {
            font-size: 20px;
            padding: 6px 30px;
          }
          @media (min-width: 1536px) {
            font-size: 25px;
            padding: 6px 30px;
          }
        }
      }

      .banner-content {
        flex-direction: column;
        @media (min-width: 1280px) {
          flex-direction: row;
        }
        @media (min-width: 1536px) {
          flex-direction: row;
        }

        .left-banner-side {
          width: 100%;
          padding-top: 50px;

          @media (min-width: 1280px) {
            width: 50%;
            padding-left: 8px;
            padding-top: 0;
          }
          @media (min-width: 1536px) {
            width: 50%;
            padding-left: 8px;
            padding-top: 0;
          }
          .title-banner {
            color: #21314a;
            z-index: 0;
            position: relative;

            .title-main {
              font-weight: 600;
              font-family: "Cabinet Grotesk", sans-serif;
              color: white;
              font-size: 32px;
              width: fit-content;
              height: auto;
              display: inline;
              @media (min-width: 414px) {
                font-size: 35px;
              }
            }

            .sub-title-main {
              font-size: 32px;
              font-weight: 600;
              font-family: "Cabinet Grotesk", sans-serif;
              text-wrap: wrap;
              width: fit-content;
              height: auto;
              display: inline;
              padding-left: 15px;
              line-height: 40px;
              @media (min-width: 414px) {
                font-size: 35px;
                padding-left: 20px;
              }
            }

            .title-image {
              position: absolute;
              top: -18px;
              z-index: -1;
              width: 180px;
              @media (min-width: 414px) {
                width: 193px;
              }
            }
          }
        }

        .container-board-side {
          width: 100%;
          height: 100%;
          padding-top: 0px;
          margin-top: -30px;
          padding-left: 0px;
          display: flex;
          align-items: center;
          z-index: 0;
          @media (min-width: 768px) {
            margin-top: -20px;
          }
          @media (min-width: 1280px) {
            width: 50%;
            padding-left: 8px;
            padding-top: 0;
            padding-left: 10px;
            margin-top: 0px;
          }
          @media (min-width: 1536px) {
            width: 50%;
            padding-left: 8px;
            padding-top: 0;
            padding-left: 10px;
            margin-top: 0px;
          }

          .container-board {
            position: relative;
            z-index: 0;
            display: flex;

            .board-content {
              display: flex;
              justify-content: center;
              z-index: 10;
              top: 0;
              bottom: 0;
              width: 100%;

              .form-content {
                text-transform: capitalize;
                width: 80%;
                height: 100%;
                top: 0;
                left: 0;
                padding: 95px 0px 25px 0px;
                z-index: 30;
                @media (min-width: 667px) {
                  width: 60%;
                  height: 100%;
                }
                @media (min-width: 768px) {
                  padding: 140px 0px 10px 0px;
                  width: 55%;
                }
                @media (min-width: 1024px) {
                  padding: 140px 0px 10px 0px;
                  width: 40%;
                }
                @media (min-width: 1280px) {
                  width: 60%;
                  padding: 105px 0px 10px 0px;
                }
                @media (min-width: 1536px) {
                  width: 65%;
                  padding: 140px 0px 10px 0px;
                }

                .line-container {
                  display: flex;
                  gap: 10px;
                  align-items: center;
                  padding: 10px 0px;
                  @media (min-width: 768px) {
                    padding: 20px 0px;
                  }
                  @media (min-width: 1280px) {
                    padding: 10px 0px;
                  }
                  @media (min-width: 1536px) {
                    padding: 20px 0px;
                  }

                  .line {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    background-color: #21314a;
                    height: 1px;
                  }
                }
              }
              .form-content h3 {
                font-family: "Cabinet Grotesk", sans-serif;
              }

              @media (min-width: 768px) {
                height: 600px;
              }
              @media (min-width: 1280px) {
                height: 520px;
              }
              @media (min-width: 1536px) {
                height: 600px;
              }
            }

            .shadow-content {
              position: absolute;
              bottom: -60px;
              z-index: -1;
              height: 100%;
              width: 100%;
              right: -200px;
              top: 70px;
              height: 90%;
              display: none;
              @media (min-width: 768px) {
                display: block;
              }
              @media (min-width: 1280px) {
                display: block;
                right: -170px;
                height: 94%;
                top: 40px;
              }
              @media (min-width: 1536px) {
                display: block;
                right: -200px;
                height: 94%;
                top: 50px;
              }
            }
          }
        }
        .container-board-sign-in {
          width: 100%;
          height: 100%;
          padding-top: 0px;
          margin-top: -30px;
          padding-left: 0px;
          display: flex;
          align-items: center;
          z-index: 0;
          @media (min-width: 768px) {
            margin-top: -20px;
          }
          @media (min-width: 1280px) {
            width: 100%;
            padding-left: 8px;
            padding-top: 0;
            padding-left: 10px;
            margin-top: 0px;
          }
          @media (min-width: 1536px) {
            width: 100%;
            padding-left: 8px;
            padding-top: 0;
            padding-left: 10px;
            margin-top: 0px;
          }

          .container-board {
            position: relative;
            z-index: 0;
            display: flex;

            .board-content {
              display: flex;
              justify-content: center;
              z-index: 10;
              top: 0;
              bottom: 0;
              width: 100%;
              height: 500px;

              .form-content {
                text-transform: capitalize;
                width: 80%;
                height: 100%;
                top: 0;
                left: 0;
                padding: 105px 0px 25px 0px;
                z-index: 30;
                @media (min-width: 667px) {
                  width: 65%;
                  height: 100%;
                }
                @media (min-width: 768px) {
                  padding: 140px 0px 10px 0px;
                  width: 55%;
                }
                @media (min-width: 1024px) {
                  padding: 140px 0px 10px 0px;
                  width: 40%;
                }
                @media (min-width: 1280px) {
                  width: 31%;
                  padding: 105px 0px 10px 0px;
                }
                @media (min-width: 1536px) {
                  width: 34%;
                  padding: 140px 0px 10px 0px;
                }

                .line-container {
                  display: flex;
                  gap: 10px;
                  align-items: center;
                  padding: 10px 0px;
                  @media (min-width: 768px) {
                    padding: 20px 0px;
                  }
                  @media (min-width: 1280px) {
                    padding: 10px 0px;
                  }
                  @media (min-width: 1536px) {
                    padding: 20px 0px;
                  }

                  .line {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    background-color: #21314a;
                    height: 1px;
                  }
                }
              }
              .form-content-forgot {
                text-transform: capitalize;
                width: 80%;
                height: 100%;
                top: 0;
                left: 0;
                padding: 125px 0px 25px 0px;
                z-index: 30;
                @media (min-width: 667px) {
                  width: 65%;
                  height: 100%;
                }
                @media (min-width: 768px) {
                  padding: 140px 0px 10px 0px;
                  width: 55%;
                }
                @media (min-width: 1024px) {
                  padding: 140px 0px 10px 0px;
                  width: 40%;
                }
                @media (min-width: 1280px) {
                  width: 31%;
                  padding: 105px 0px 10px 0px;
                }
                @media (min-width: 1536px) {
                  width: 34%;
                  padding: 140px 0px 10px 0px;
                }
              }
              .form-content h3 {
                font-family: "Cabinet Grotesk", sans-serif;
              }
              .form-content-forgot h3 {
                font-family: "Cabinet Grotesk", sans-serif;
              }

              @media (min-width: 768px) {
                height: 600px;
              }
              @media (min-width: 1280px) {
                height: 520px;
              }
              @media (min-width: 1536px) {
                height: 600px;
              }
            }

            .shadow-content {
              position: absolute;
              bottom: -60px;
              z-index: -1;
              height: 100%;
              width: 100%;
              right: -200px;
              top: 70px;
              height: 90%;
              display: none;
              @media (min-width: 768px) {
                display: block;
              }
              @media (min-width: 1280px) {
                display: block;
                right: -170px;
                height: 94%;
                top: 40px;
              }
              @media (min-width: 1536px) {
                display: block;
                right: -200px;
                height: 94%;
                top: 50px;
              }
            }
          }
        }

        .container-board-sign-up {
          width: 100%;
          height: 100%;
          padding-top: 0px;
          margin-top: -30px;
          padding-left: 0px;
          display: flex;
          align-items: center;
          z-index: 0;
          @media (min-width: 768px) {
            margin-top: -20px;
          }
          @media (min-width: 1280px) {
            width: 100%;
            padding-left: 8px;
            padding-top: 0;
            padding-left: 10px;
            margin-top: 0px;
          }
          @media (min-width: 1536px) {
            width: 100%;
            padding-left: 8px;
            padding-top: 0;
            padding-left: 10px;
            margin-top: 0px;
          }

          .container-board {
            position: relative;
            z-index: 0;
            display: flex;

            .board-content {
              display: flex;
              justify-content: center;
              z-index: 10;
              top: 0;
              bottom: 0;
              width: 100%;
              height: 100%;

              .board-image {
                display: none;
                @media (min-width: 768px) {
                  display: block;
                }
              }

              .form-content-forgot {
                text-transform: capitalize;
                position: relative;
                width: 100%;
                top: 0;
                left: 0;
                padding: 40px 15px 15px 15px;
                border: 15px solid #956745;
                border-radius: 20px;
                z-index: 30;
                @media (min-width: 768px) {
                  padding: 135px 0px 10px 0px;
                  width: 65%;
                  border: none;
                }
                @media (min-width: 1024px) {
                  padding: 140px 0px 10px 0px;
                  width: 50%;
                  border: none;
                }
                @media (min-width: 1280px) {
                  width: 41%;
                  border: none;
                  padding: 135px 0px 10px 0px;
                }
                @media (min-width: 1536px) {
                  width: 40%;
                  border: none;
                  padding: 140px 0px 10px 0px;
                }
                .clip-image {
                  position: absolute;
                  z-index: 20;
                  top: -57px;
                  left: 0;
                  display: flex;
                  justify-content: center;
                  width: 100%;
                  @media (min-width: 768px) {
                    display: none;
                  }
                }
              }
              .form-content h3 {
                font-family: "Cabinet Grotesk", sans-serif;
              }
              .form-content-forgot h3 {
                font-family: "Cabinet Grotesk", sans-serif;
              }

              @media (min-width: 768px) {
                height: 700px;
              }
              @media (min-width: 1024px) {
                height: 710px;
              }
              @media (min-width: 1280px) {
                height: 700px;
              }
              @media (min-width: 1536px) {
                height: 720px;
              }
            }

            .shadow-content {
              position: absolute;
              bottom: -60px;
              z-index: -1;
              height: 100%;
              width: 100%;
              right: -200px;
              top: 70px;
              height: 90%;
              display: none;
              @media (min-width: 768px) {
                display: block;
                right: -230px;
                top: 85px;
              }
              @media (min-width: 1024px) {
                display: block;
                right: -230px;
                height: 94%;
                top: 60px;
              }
              // @media (min-width: 1280px) {
              //   display: block;
              //   right: -230px;
              //   height: 94%;
              //   top: 60px;
              // }
              @media (min-width: 1536px) {
                display: block;
                right: -240px;
                height: 94%;
                top: 56px;
              }
            }
          }
        }
      }
    }
  }
}

.section-home {
  background-color: #f3f3f3;
  padding-top: 40px;
  padding-bottom: 20px;
  .section-home-item {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    width: 100%;
    gap: 20px;
    @media (min-width: 786px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .item-section {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-column: span 2 / span 2;
      gap: 0px;
      @media (min-width: 786px) {
        gap: 20px;
        grid-column: span 3 / span 3;
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }
  }
  .line {
    height: 1px;
    background-color: black;
    width: 15px;
  }
}

.title-section-primary {
  border-left: 5px solid #048ec3;
  padding-left: 10px;
  text-transform: capitalize;
  font-family: "Cabinet Grotesk", sans-serif;
}

.title-section-secondary {
  border-left: 5px solid #fc8801;
  padding-left: 10px;
  text-transform: capitalize;
  font-family: "Cabinet Grotesk", sans-serif;
}

.home-sections {
  background-color: white;
  .home-section-container {
    padding: 80px 0px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    @media (min-width: 1280px) {
      flex-direction: column;
      padding: 150px 0px;
      gap: 0;
    }
    .home-section-content {
      display: flex;
      width: 100%;
      align-items: center;
      gap: 20px;
      flex-direction: column;
      @media (min-width: 1280px) {
        flex-direction: row;
        gap: 0;
      }
      .image-container {
        width: 100%;
        position: relative;
        height: auto;
        @media (min-width: 1280px) {
          width: 50%;
        }
      }

      .image-container img {
        object-fit: cover;
        width: 100%;
        height: auto;
        display: block;
        border-radius: 30px 30px 30px 30px;
        @media (min-width: 1280px) {
          width: 50%;
          border-radius: 30px 0px 0px 30px;
        }
      }
      .description-container {
        width: 100%;
        padding-left: 0;
        @media (min-width: 1280px) {
          padding-left: 40px;
          width: 50%;
        }
      }
    }
    .home-section-content:nth-child(even) {
      flex-direction: column;
      @media (min-width: 1280px) {
        flex-direction: row-reverse;
      }
      .image-container img {
        border-radius: 30px;
        @media (min-width: 1280px) {
          width: 50%;
          border-radius: 0px 30px 30px 0px;
        }
      }
      .description-container {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        @media (min-width: 1280px) {
          padding-right: 40px;
          padding-left: 0;
          width: 50%;
        }
      }
    }
  }
}
